import { useEffect, useState } from 'react';
import {
  firestore,
  collection,
  doc,
  updateDoc,
  onSnapshot,
  setDoc,
  auth,
} from './firebase';
import { getIdsAndData, getCustomUid } from './utilities';
import { initialSkiData, initialSnowboardData } from './static-data';
import SignInOrSignUp from './components/SignInOrSignUp';
import SignOut from './components/SignOut';
import MasteryGrid from './components/MasteryGrid';
import CapitalisedHeading from './components/CapitalisedHeading';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import './App.css';

const dataSubscription = (setData, customUid) => {
  if (customUid) {
    const unsubscribe = onSnapshot(
      collection(firestore, 'users', customUid, 'disciplines'),
      (snapshot) => {
        const data = snapshot.docs?.map(getIdsAndData);
        setData(data);
      },
    );

    return unsubscribe;
  }

  return undefined;
};

const Notes = ({ setNotes: returnNotes }) => {
  const [notes, setNotes] = useState('');

  const handleOnChange = (event) => {
    const { value } = event.target; // can destructure other props from the input like name
    setNotes(value);
  };

  const setNotesSubmit = (event) => {
    event.preventDefault();
    returnNotes(notes);
  };

  return (
    <form onSubmit={setNotesSubmit} className="AddPost">
      <input
        type="text"
        name="notes"
        placeholder="Notes"
        value={notes}
        onChange={handleOnChange}
      />
      <input className="create" type="submit" />
    </form>
  );
};

const shouldAddDiscipline = (data, discipline) =>
  !Boolean(data?.find(({ id }) => id === discipline)?.shouldShow);

const initialiseMasteryData = async (customUid, discipline, skills) => {
  const args = ['users', customUid, 'disciplines', discipline];
  await setDoc(doc(firestore, ...args), {
    shouldShow: true,
    skills,
  });
};

const showData = async (data, customUid, discipline) => {
  const disciplineData = data?.find(({ id }) => id === discipline);
  const args = ['users', customUid, 'disciplines', discipline];
  const docRef = doc(firestore, ...args);
  await updateDoc(docRef, { ...disciplineData, shouldShow: true });
};

const handleAddDiscipline = async (
  data,
  customUid,
  discipline,
  initialSkills,
) => {
  const alreadyExists =
    data?.find(({ id }) => id === discipline)?.shouldShow === false;

  alreadyExists
    ? await showData(data, customUid, discipline)
    : await initialiseMasteryData(customUid, discipline, initialSkills);
};

function App() {
  const [user, setUser] = useState(null);
  const [data, setData] = useState();
  const [notes, setNotes] = useState('');
  const customUid = getCustomUid(user, user?.displayName);

  // set user after page reload
  useEffect(() => {
    auth.onAuthStateChanged((changedUser) =>
      setUser(changedUser.auth.currentUser),
    );
  }, [auth]);

  // subscribe to database - get data and updates
  useEffect(() => {
    const unsubscribeFunc = dataSubscription(setData, customUid);
    const noOp = () => {};

    return unsubscribeFunc || noOp;
  }, [user, customUid]);

  // get collection and update it
  useEffect(() => {
    if (data && notes) {
      const args = ['users', customUid, 'disciplines', 'ski'];
      const docRef = doc(firestore, ...args);
      const skiData = data.find(({ id }) => id === 'ski');
      const updatedData = { ...skiData };
      // eslint-disable-next-line no-unused-expressions
      updatedData.skills[0].phases[0].notes.push(notes);
      updateDoc(docRef, updatedData);
    }
  }, [notes]);

  return (
    <div className="App">
      <header className="App-header">Cross Snowsports UK</header>
      {!user ? (
        <SignInOrSignUp setUser={setUser} />
      ) : (
        <>
          <CapitalisedHeading>Hi {user.displayName}</CapitalisedHeading>
          <MasteryGrid data={data} customUid={customUid} />
          <Stack spacing={3}>
            {shouldAddDiscipline(data, 'ski') && (
              <Button
                type="button"
                fullWidth
                variant="text"
                sx={{ mt: 3, mb: 2 }}
                onClick={() =>
                  handleAddDiscipline(data, customUid, 'ski', initialSkiData)
                }
              >
                Add Ski Mastery
              </Button>
            )}
            {shouldAddDiscipline(data, 'snowboard') && (
              <Button
                type="button"
                fullWidth
                variant="text"
                sx={{ mt: 3, mb: 2 }}
                onClick={() =>
                  handleAddDiscipline(
                    data,
                    customUid,
                    'snowboard',
                    initialSnowboardData,
                  )
                }
              >
                Add Snowboard Mastery
              </Button>
            )}
          </Stack>
          <SignOut setUser={setUser} />
        </>
      )}
      <p>Data: {JSON.stringify(data)}</p>
      <Notes setNotes={setNotes} />
    </div>
  );
}

export default App;
